import React from "react"
import {isAuth} from "../services/auth";
import Header from "../partials/Header";
import FileList from "../partials/FileList";
import {GET} from "../services/api"
import "../css/main.css"

function Dashboard() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("redirectUrl")
    if (redirectUrl) {
        GET(redirectUrl).then((data)=>{
            window.location.href = "/document/view/" + data["id"]
        })
    }
    React.useEffect(() => {
        isAuth()
    }, [])
    return (
        <main>
            <Header/>
            <FileList/>
        </main>
    )
}

export default Dashboard