import React from "react"
import "../css/signin.css"
import {showError} from "../auth/utils";
import {resetPassword} from "../services/api";
import {isAuth} from "../services/auth";

function Reset() {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const refPassword = React.useRef()
    const refConfirmPassword = React.useRef()
    const [token, setToken] = React.useState("")
    const handlerSubmit = async (e) => {
        e.preventDefault()
        if (!isSubmitting) {
            setIsSubmitting(true)
            document.getElementById("submit").classList.add("process")
            if (refPassword.current.value !== refConfirmPassword.current.value) {
                showError("Passwords missmatch")
            }

            const data = await resetPassword(token, refPassword.current.value)
            if (data.msg ) {
                document.getElementById("submit").classList.remove("process")
                setIsSubmitting(false)
                window.location.href = "/signin"
            }
        }

    }
    React.useEffect(() => {
        const takeAuth = async () => {
            const result = await isAuth()
            if (result) {
                window.location.href = "/"
            }
        }
        takeAuth()

        setToken(
(window.location.href.match(new RegExp('[?&]' + "token" + '=([^&]+)')) || [, null])[1])
    }, [])
    return (
        <form className="form-signin" onSubmit={handlerSubmit} method="POST">
            <img className="mb-4" src="/img/logo/logo.svg" alt="" width="72" height="72"/>
            <h1 className="form-sign-h1">Set new password</h1>
            <span>Your new password must different to previos password</span>
            <div className="alert alert-danger" id="error" style={{"display": "none"}}>
                A simple danger alert—check it out!
            </div>
            <label htmlFor="inputPassword">Password</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Password" required=""
                   ref={refPassword}
            />
            <label htmlFor="inputPassword">Confirm Password</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Confirm Password"
                   ref={refConfirmPassword}
                   required=""/>
            <button className="btn btn-lg btn-primary btn-block" type="submit" id="submit">
                {!isSubmitting ? 'Reset' : 'Reseting ...'}
            </button>
            <span>Back to <a href="/signin">Sign in</a></span>
        </form>
    )
}

export default Reset