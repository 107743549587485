import React from "react"
import {getDocuments} from "../services/api";
function FileList() {
    const [documents, setDocuments] = React.useState([])
    React.useEffect(()=>{
        getDocuments().then((data)=>{
            setDocuments(data)
        })
    }, [])
    return (<ul className={"list-group"}>
            {documents.map((entity, index) => {
                return (
                    <li className={"list-group-item"} key={index}>{entity.name}
                        &nbsp;<a href={"/document/view/" + entity.id}>view</a>
                        &nbsp;<a href={"/document/edit/" + entity.id}>edit</a></li>
                )
            })}
        </ul>
    )
}

export default FileList