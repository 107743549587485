import React from "react"
import "../css/signin.css"
import {Login} from "../services/api"
import {saveToken} from "../services/token";
import {isAuth} from "../services/auth";
import {showError} from "../auth/utils";

function Signin() {
    const refUsername = React.useRef()
    const refPassword = React.useRef()
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [redirectUrl, setRedirectUrl] = React.useState("")
    const handlerSubmit = async (e) => {
        e.preventDefault()
        if (!isSubmitting) {
            setIsSubmitting(true)
            document.getElementById("submit").classList.add("process")
            const data = await Login({
                "username": refUsername.current.value,
                "password": refPassword.current.value
            })

            if (data.status === 200) {
                saveToken(data.data.access_token);
                window.location.pathname = '/';
            } else {
                showError(data)
                refUsername.current.value = ""
                refUsername.current.focus()

                refPassword.current.value = ""
                setIsSubmitting(false)
                document.getElementById("submit").classList.remove("process")
            }

        }
    }

    React.useEffect(() => {
        const takeAuth = async () => {
            const result = await isAuth()
            if (result) {
                window.location.href = "/"
            }
        }
        takeAuth().then((data)=>{
            //console.log(data)
        })
        const urlParams = new URLSearchParams(window.location.search);
        setRedirectUrl(urlParams.get("redirectUrl"))
    }, [])
    return (
        <form className="form-signin" onSubmit={handlerSubmit} method="POST">
            <img className="mb-4" src="/img/logo/logo.svg" alt="" width="72" height="72"/>
            <h1 className="form-sign-h1">Welcome back!</h1>
            <span>Please enter your credentials to sign in!</span>
            <div className="alert alert-danger" id="error" style={{"display": "none"}}>
                A simple danger alert—check it out!
            </div>
            <label htmlFor="inputEmail">Email address</label>
            <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required=""
                   autoFocus="" ref={refUsername}/>
            <label htmlFor="inputPassword">Password</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Password" required=""
                   ref={refPassword}/>
            <a href="/forgot" className="forgot">Forgot password?</a>
            <button className="btn btn-lg btn-primary btn-block btn-secondary" type="submit" id="submit">
                {!isSubmitting ? 'Sign in' : 'Signin ...'}
            </button>
            <span>Don't have an account yet? <a href={"/signup?redirectUrl=" + redirectUrl}>Sign up</a></span>
        </form>
    )
}

export default Signin