const showError = (data) => {
    const error = document.getElementById("error")
    let message = data
    if (data.hasOwnProperty("response")) {
        message = data.response
        if (data.response) {
            message = data.response.data
            if (data.response.data.hasOwnProperty("detail"))
            message = data.response.data.detail;
        }
    }
    if ( error ) {
        error.style = "display: block"
        error.innerHTML = message
    }
    setTimeout(()=>{
        document.getElementById("error").style = "display: none"
    }, 3000)

}
export {showError}