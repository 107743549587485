import React from "react"
import {isAuth} from "../../services/auth";
import Header from "../../partials/Header";
import {createDocument} from "../../services/api";
import "../../css/main.css"

function DocumentNew() {
    const [document, setDocument] = React.useState(File | null)
    const handleFileChange = (e) => {
        if (e.target.files) {
            setDocument(e.target.files[0]);
        }
    };
    const handlerSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("document", document)

        createDocument(formData).then((data) => {

            window.location.href = "/"

        })

    }
    React.useEffect(() => {
        isAuth()
    }, [])
    return (
        <main>
            <Header/>
            <form method="POST" onSubmit={handlerSubmit}>
                <input type="file" onChange={handleFileChange}/>
                <button className={"btn bg-indigo-500 hover:bg-indigo-600 text-white addFile"}
                        type="submit">
                    Upload
                </button>
            </form>
        </main>
    )
}

export default DocumentNew