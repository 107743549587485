import React from "react"
import "../css/signin.css"
import {isAuth} from "../services/auth";
import {showError} from "../auth/utils";
import {registerUser} from "../services/api";

function Signin() {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const refEmail = React.useRef()
    const refUsername = React.useRef()
    const refPassword = React.useRef()
    const refConfirmPassword = React.useRef()
    const handlerSubmit = async (e) => {
        e.preventDefault()
        if (!isSubmitting) {
            setIsSubmitting(true)
            document.getElementById("submit").classList.add("process")
            document.getElementById("submit").classList.add("process")
            if (refPassword.current.value !== refConfirmPassword.current.value) {
                showError("Passwords missmatch")

            }
            const data = await registerUser(refUsername.current.value,
                refEmail.current.value,
                refPassword.current.value
            )

            if (data.email === refEmail.current.value) {
                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get("redirectUrl")) {
                    window.location.href = "/signin?redicrectUrl=" + urlParams.get("redirectUrl")
                } else {
                    window.location.href = "/signin"
                }
            } else {
                showError(data)
            }
            document.getElementById("submit").classList.remove("process")
            setIsSubmitting(false)
        }
    }
    React.useEffect(() => {
        const takeAuth = async () => {
            const result = await isAuth()
            if (result) {
                window.location.href = "/"
            }
        }
        takeAuth()

    }, [])
    return (
        <form className="form-signin" onSubmit={handlerSubmit} method="POST">
            <img className="mb-4" src="/img/logo/logo.svg" alt="" width="72" height="72"/>
            <h1 className="form-sign-h1">Sign Up</h1>
            <span>And lets get started with your free trial</span>
            <div className="alert alert-danger" id="error" style={{"display": "none"}}>
                A simple danger alert—check it out!
            </div>
            <label htmlFor="inputEmail">Username</label>
            <input type="text" id="inputName" className="form-control" placeholder="Username" required=""
                   ref={refUsername}/>
            <label htmlFor="inputEmail">Email address</label>
            <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required=""
                   ref={refEmail}/>
            <label htmlFor="inputPassword">Password</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Password" required=""
                   ref={refPassword}
            />
            <label htmlFor="inputPassword">Confirm Password</label>
            <input type="password" id="inputPassword" className="form-control" placeholder="Confirm Password"
                   required="" ref={refConfirmPassword}/>
            <button className="btn btn-lg btn-primary btn-block" type="submit" id="submit">
                {!isSubmitting ? 'Sign up' : 'Sign up  ...'}
            </button>
            <span>Already have an account? <a href="/signin">Sign in</a></span>
        </form>
    )
}

export default Signin