import React from "react"
import {GET, getUserMe} from "../services/api"
function Fill() {
    const [loading, setLoading] = React.useState(false)
    const params = new URLSearchParams(document.location.search)
    const id = params.get("id")
    if (!loading) {
        getUserMe().then((data) => {
            if (data.hasOwnProperty("response") && data.response.status === 403) {
                window.location.href = "/signin?redirectUrl=/api/documents/fill/" + id
            } else {
                GET("/api/documents/fill/" + id).then((data)=>{
                    if ( data["id"] ) {
                        window.location.href = "/document/view/" + data["id"]
                    }
                })
            }
        })
        setLoading(true)
    }
}
export default Fill