function Header() {
    const addDocument = () => {
        window.location.href = "/document/new"
    }
    return (<nav className={"navbar navbar-expand-lg navbar-light bg-light"}>
        <a className={"navbar-brand"} href="/">PDF MAX</a>

        <div className={"collapse navbar-collapse"} id="navbarSupportedContent">
            <ul className={"navbar-nav mr-auto"}>
                <li className={"nav-item active"}>
                    <a className={"nav-link"} href="#">My BOX</a>
                </li>
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#">Share with me</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#">Send to sign</a>*/}
                {/*</li>*/}
                {/*<li className="nav-item">*/}
                {/*    <a className="nav-link" href="#">Sign to me</a>*/}
                {/*</li>*/}


            </ul>
            <button type="button" className={"btn btn-success addDocument"} onClick={addDocument}>Add Document</button>

        </div>
    </nav>)
}

export default Header