import {Routes, Route} from 'react-router-dom'

import Reset from './pages/Reset'
import Forgot from './pages/Forgot'
import Signin from './pages/Signin'
import Signup from './pages/Signup'

import Dashboard from './pages/Dashboard'

import DocumentNew from "./pages/documents/new";
import DocumentView from "./pages/documents/view";
import DocumentEdit from "./pages/documents/edit";

import Fill from "./pages/fill";

function App() {
    return (
        <>
            <Routes>

                <Route path="/reset" element={<Reset />}/>
                <Route path="/forgot" element={<Forgot />}/>
                <Route path="/signin" element={<Signin />}/>
                <Route path="/signup" element={<Signup />}/>

                <Route path="/" element={<Dashboard />}/>
                <Route path="/document/new" element={<DocumentNew />} />
                <Route path="/document/view/:id" element={<DocumentView />} />
                <Route path="/document/edit/:id" element={<DocumentEdit />} />

                <Route path="/fill" element={<Fill />} />
            </Routes>
        </>
    );
}

export default App;
