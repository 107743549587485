import React from "react"
import {isAuth} from "../../services/auth";
import Header from "../../partials/Header";
import {useParams} from 'react-router-dom';
import {getDocument} from "../../services/api";
import "../../css/main.css"


function DocumentEdit() {
    const {id} = useParams();
    const [doc, setDoc] = React.useState([])

    React.useEffect(() => {
        isAuth()
        getDocument(id).then((data) => {
            setDoc(data)
        })


     }, [id])
    return (
        <main>
            <Header/>
            <div>ID: {doc.id}</div><br />
            <div>Name: {doc.name}</div>
            <div>File: {doc.file}</div>
            <div>Ext: {doc.ext}</div>
            <div id="pspdfkit"></div>
            <div>Document View</div>
        </main>
    )
}

export default DocumentEdit