import React from "react"
import {isAuth} from "../../services/auth";
import Header from "../../partials/Header";
import {useParams} from 'react-router-dom';
import {getDocument} from "../../services/api";
import "../../css/main.css"

function DocumentView() {
    const {id} = useParams();
    const [doc, setDoc] = React.useState([])

    const [loaded, setLoaded] = React.useState(false)
    const loadPDF = () => {
        setLoaded(true)
    }

    React.useEffect(() => {
        isAuth()
        getDocument(id).then((data) => {
            setDoc(data)
        })
        loadPDF()


    }, [id])
    return (
        <div><Header />
            {loaded && <div>
                <iframe src={doc.file} width="100%" height="1200px" title="PDF Document" />
            </div>}
        </div>
    )
}

export default DocumentView