import React from "react"
// import "../css/signin.css"
import {isAuth} from "../services/auth";
import {forgotPassword} from "../services/api";

function Forgot() {
    const refUsername = React.useRef()

    const [isSubmitting, setIsSubmitting] = React.useState(false)

    const handlerSubmit = async (e) => {
        e.preventDefault()
        if (!isSubmitting) {
            setIsSubmitting(true)
            document.getElementById("submit").classList.add("process")
            const data = await forgotPassword(refUsername.current.value)
            if (data.msg) {
                document.getElementById("forgot").innerHTML = "<span>Password reset link is sent</span>"
            }
        }
    }
    React.useEffect(() => {
        const takeAuth = async () => {
            const result = await isAuth()
            if (result) {
                window.location.href = "/"
            }
        }

        takeAuth()

    }, [])
    return (
        <form className="form-signin" onSubmit={handlerSubmit} method="POST">
            <img className="mb-4" src="/img/logo/logo.svg" alt="" width="72" height="72"/>
            <h1 className="form-sign-h1">Forgot Password</h1>
            <div id="forgot">
                <span>Please enter your email address to receive a verification code</span>
                <label htmlFor="inputEmail">Email address</label>
                <input type="email" id="inputEmail" className="form-control" placeholder="Email address" required=""
                       autoFocus="" ref={refUsername}/>

                <button className="btn btn-lg btn-primary btn-block btn-secondary" type="submit" id="submit">
                    {!isSubmitting ? 'Forgot' : 'Forgot ...'}
                </button>
            </div>
            <span>Back to <a href="/signin">Sign in</a></span>
        </form>
    )
}

export default Forgot