import {getUserMe} from "./api";
const noSignin = window.location.pathname !== "/signin"
                    && window.location.pathname !== "/signup"
                    && window.location.pathname !== "/forgot"
                    && window.location.pathname !== "/reset"
const isAuth = () =>
    getUserMe().then((data) => {
        if (data.hasOwnProperty("response")) {
            if (data.response.status === 403) {
                if (noSignin) {
                    window.location.replace("/signin");
                }
                return false
            }
        }
        if (data["email"] === undefined) {
            if (noSignin) window.location.replace("/signin");
            return false
        }
        return true
    });
export {isAuth}